import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ConsoleAPI from '../../api/ConsoleAPI';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

function PaymentCompletePage({ navigation }) {

  const { id } = useParams();
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    loadOrder();
  }, []);

  const loadOrder = () => {
    ConsoleAPI.getOrderTracking(id, (data) => {
      console.log(data);
      if (data && data.totalCost) {
        setOrderId(data.orderId);
        /*
        window.dataLayer.push({
          event: 'purchase',
          value: data.totalCost.toFixed(2),
          id: data.orderId
        });
        */
      }
    });
  }

  return (
    <div>

      <Header />

      <main id="main-content">
        <div className="owl-carousel header-slider">
          <div className="slide main-bg"></div>
        </div>

        <div className="row">
  <div className="small-12">
    <div className="content-frame">
      <div className="header-content">
        <h2 className="text-center">Thank you for your payment.</h2>
        <p className="text-center intro-title">Your Order Number is</p>
        <h2 className="text-center">{orderId}</h2>
        <p className="text-center intro-title">Please keep this Order Number safe so that the team can help with any queries you may have with regards to your order.</p>
        <h2 className="text-center">What happens next...</h2>

        <p className="text-center intro-title">
          Your payment will be validated and your order released. eGift Cards
          will be delivered to you within 1 working day of payment being
          received; these are delivered via email with a spreadsheet containing
          eGift URL’s. An SMS passcode is sent to the mobile number provided on
          order placement, and this is used to unlock the spreadsheet containing
          the eGift Card URLs.
        </p>

        <p className="text-center intro-title">
          Physical Gift Cards will be despatched within 2 working days of
          payment being received, delivered by our courier DPD. Gift Cards will
          be active on receipt and do not require activation.
        </p>

        <p className="text-center intro-title">
          If you have a question, please check out the FAQ’s page first <a href="https://www.sainsburysforbusiness.co.uk/frequently-asked-questions/">here</a>, 
          as this may get you to an answer sooner. For any
          issues, please contact our Gifting Account Support team at{' '}
          <a href="mailto:afb.giftcards@sainsburys.co.uk">
            afb.giftcards@sainsburys.co.uk</a>
        </p>
      </div>
    </div>
  </div>
</div>


      </main>

      <Footer />

    </div>
  )

}

export default PaymentCompletePage;
