import React from 'react';

class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      envCode: ''
    }

  }

  componentDidMount() {

    let _env = process.env.REACT_APP_DPI_ENV;
    if (_env === "staging") {
      this.setState({
        envCode: 'S'
      });
    } else if (_env === "development") {
      this.setState({
        envCode: 'D'
      });
    }
  }

  render() {
    return (
      <>
        {/*
        <footer className="footer">
          <div className="row footer__content">

            <div className="small-12 medium-6 large-6">
              <h4>Useful Information</h4>
              <ul className="footer-menu">
                <li><a href="https://argosforbusiness.co.uk/wp-content/uploads/Non-Account-Gift-Card-Purchasing-Terms.pdf">Terms &amp; Conditions</a></li>
                <li><a href="https://argosforbusiness.co.uk/privacy-policy/">Privacy Policy</a></li>
              </ul>
            </div>
            <div className="small-12 medium-6 large-6">
              <h4>Contact</h4>
              <ul className="footer-menu">
                <li><a href="mailto:afb.giftcards@sainsburys.co.uk">afb.giftcards@sainsburys.co.uk</a></li>
              </ul>
            </div>

          </div>

          <div className="row footer__copyright">
            <div className="small-12 medium-12">
              <p className="copyright">&copy; 2020 Argos for Business.<br />Version {version}{this.state.envCode}.</p>
            </div>
          </div>

        </footer>
    */}
        <footer className="footer">
          <div className="row">

            <div className="small-12 medium-4 large-4">
              <h4>Sainsbury's for Business</h4>
              <ul className="footer-menu">
                <li><a href="https://sainsburysforbusiness.co.uk/gift-cards/">Gift Cards</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/why-choose-us/">Why choose us?</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/how-to-buy/">How to Buy</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/regular-gifting/">Regular Gifting</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/contact/">Contact</a></li>
              </ul>
            </div>
            <div className="small-12 medium-4 large-4">
              <h4>More from Sainsbury's</h4>
              <ul className="footer-menu">
                <li><a target="_blank" rel="noopener noreferrer" href="https://tuclothing.sainsburys.co.uk/">TU</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.argos.co.uk/?cmpid=SB-GM-12">Argos</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.habitat.co.uk/">Habitat</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.sainsburysbank.co.uk/">Sainsbury’s Bank</a></li>

              </ul>
            </div>
            <div className="small-12 medium-4 large-4">
              <h4>Useful Information</h4>
              <ul className="footer-menu">
                <li><a target="_blank" rel="noopener noreferrer" href="https://privacy-hub.sainsburys.co.uk/privacy-policy/">Privacy Policy</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://privacy-hub.sainsburys.co.uk/cookie-policy/">Cookie Policy</a></li>
                <li><a href="https://stores.sainsburys.co.uk/">Store Locator</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/wp-content/uploads/2023/12/Non-Account-Gift-Card-Purchasing-Terms-October-2023.pdf">Non Account Gift Card Purchasing Terms</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/standard-gift-card-account-terms/">Standard Gift Card Account Terms</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/enhanced-gift-card-account-terms/">Enhanced Gift Card Account Terms</a></li>
                <li><a href="https://sainsburysforbusiness.co.uk/frequently-asked-questions/">FAQs</a></li>
              </ul>
            </div>

            <div className="small-12 medium-4 large-4">
              <h4>Contact</h4>
              <ul className="footer-menu">
                <li><a href="mailto:afb.giftcards@sainsburys.co.uk">afb.giftcards@sainsburys.co.uk</a></li>
              </ul>
            </div>

          </div>

          <div className="row footer__copyright" data-id="0802240705">
            <div className="small-12 medium-12">
              <p className="copyright">&copy; Sainsbury's Supermarkets Ltd 2024.<br />Version 2.3.5</p>
            </div>
          </div>

        </footer>
      </>
    )
  };

}

export default Footer;
