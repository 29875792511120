import React, { useState, useEffect } from "react";
import ConsoleAPI from "../../api/ConsoleAPI";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

function DiscountsPage({ navigation }) {
  const [discounts, setDiscounts] = useState([]);
  useEffect(() => {
    loadDiscounts();
  }, []);

  const loadDiscounts = () => {
    console.log("loadDiscounts");
    ConsoleAPI.getDiscounts((data) => {
      console.log(data);
      setDiscounts(data);
    });
  };

  return (
    <div>
      <Header />

      <main id="main-content">
        <div className="owl-carousel header-slider">
          <div className="slide main-bg"></div>
        </div>

        <div className="row">
          <div className="small-12">
            <div className="content-frame">
              <div className="header-content">
                <h2 className="text-center">
                  Discounts Available on Bulk Orders
                </h2>
                <table className="ln-c-table">
                  <thead
                    className="ln-c-table__header"
                    style={{ borderBottom: 0 }}
                  >
                    <tr className="ln-c-table__row ln-c-table__header-row">
                      <th
                        className="ln-c-table__header-cell customCol"
                        width="40%"
                      >
                        Brand
                      </th>
                      <th
                        className="ln-c-table__header-cell customCol"
                        width="30%"
                      >
                        Purchases From
                      </th>
                      <th
                        className="ln-c-table__header-cell customCol"
                        width="30%"
                      >
                        Discount Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ln-c-table__body">
                    {discounts.map((item, index) => (
                      <tr
                        key={index}
                        className="ln-c-table__row"
                        style={{ background: "#FFFFFF" }}
                      >
                        <td className="customCol ln-c-table__cell">
                          {item.Brand}
                        </td>
                        <td className="customCol ln-c-table__cell">
                          {item.appliesFrom === 0 && item.appliesTo === 0
                            ? ""
                            : item.appliesFrom === 0
                            ? `Below £${item.appliesTo.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`
                            : item.appliesTo === 0
                            ? `£${item.appliesFrom.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}+`
                            : `£${item.appliesFrom.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })} to £${item.appliesTo.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`}
                        </td>
                        <td className="customCol ln-c-table__cell">
                          {item.DiscountRate}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default DiscountsPage;
